<template>
  <b-card-code title="Ajout d'une semaine">
    <b-card-text>
      <span>Veuillez saisir les coordonnées du semaine </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Type Semaine"
              label-for="Type Semaine"
            >
              <validation-provider
                #default="{ errors }"
                name="Type Semaine"
                rules="required"
              >
                <v-select
                  v-model="semaines.type_week"
                  :clearable="false"
                  :options="type_week"
                  label="type semaine"
                  placeholder="Type semaine"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5>Date début semaine</h5>
            <b-form-datepicker
              id="example-datepicker"
              v-model="semaines.start_date"
              class="mb-1"
              placeholder="Date début semaine"
            />
          </b-col>
          <b-col md="6">
            <h5>Date Fin Semaine</h5>
            <b-form-datepicker
              id="example-datepickerr"
              v-model="semaines.end_date"
              class="mb-1"
              placeholder="Date Fin semaine"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="description"
              label-for="description"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-input
                  v-model="semaines.description"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé Abrégé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addSemaine"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import storeAuth from '@/store/store'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
  },
  data() {
    return {
      password: '',
      semaines: {
        type_week: '',
        end_date: '',
        start_date: '',
        description: '',
        academic_year_id: '',
      },
      required,
      type_week: ['cours', 'ds', 'examen', 'seminiare', 'certification', 'revision', 'vacances', 'soutenance', 'mis a niveau', 'examen de rattrapage'],

    }
  },
  created() {
    this.semaines.academic_year_id = storeAuth.state.currentAcademicYear.id
  },
  methods: {
    async addSemaine() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/weeks/create/',
                {
                  type_week: this.semaines.type_week,
                  end_date: this.semaines.end_date,
                  start_date: this.semaines.start_date,
                  description: this.semaines.description,
                  academic_year: this.semaines.academic_year_id,
                },
              )
            this.$router.push('/weeks')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Semaine ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
